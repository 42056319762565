import Script from 'next/script';
import { parseSummary } from "helpers";
const Permutive = (props) => {
    const workspaceId = "353b4747-6720-4d07-915b-94054f33fe7b";
    const workspaceApiKey = "9c50d46d-3afa-4194-8bdc-cc2817fce50a";

    const {
        sections,
        pageType,
        newsType,
        tagsArray,
        summary,
        clKeywordslist,
        section = ""
    } = props;
    const summaryParse = summary?.replace(/<\/?[^>]+(>|$)/g, "").replace(/'/g, '')
    let sectionP = section ? section : sections;
    return (<>
        <Script strategy="afterInteractive" dangerouslySetInnerHTML={{
            __html: `
                window.googletag = window.googletag || {};
                window.googletag.cmd = window.googletag.cmd || [];
                window.googletag.cmd.push(function () {
                    if (window.googletag.pubads().getTargeting('permutive').length === 0) {
                        var kvs = window.localStorage.getItem('_pdfps');
                        window.googletag.pubads().setTargeting('permutive', kvs ? JSON.parse(kvs) : []);
                    }
                });
            `
        }} />        
        <Script strategy="afterInteractive" dangerouslySetInnerHTML={{
            __html: `
                !function(e,o,n,i){if(!e){e=e||{},window.permutive=e,e.q=[];var t=function(){return([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,function(e){return(e^(window.crypto||window.msCrypto).getRandomValues(new Uint8Array(1))[0]&15>>e/4).toString(16)})};e.config=i||{},e.config.apiKey=o,e.config.workspaceId=n,e.config.environment=e.config.environment||"production",(window.crypto||window.msCrypto)&&(e.config.viewId=t());for(var g=["addon","identify","track","trigger","query","segment","segments","ready","on","once","user","consent"],r=0;r<g.length;r++){var w=g[r];e[w]=function(o){return function(){var n=Array.prototype.slice.call(arguments,0);e.q.push({functionName:o,arguments:n})}}(w)}}}(window.permutive,"${workspaceApiKey}","${workspaceId}",{"consentRequired": false});
                window.googletag=window.googletag||{},window.googletag.cmd=window.googletag.cmd||[],window.googletag.cmd.push(function(){if(0===window.googletag.pubads().getTargeting("permutive").length){var e=window.localStorage.getItem("_pdfps");window.googletag.pubads().setTargeting("permutive",e?JSON.parse(e):[]);var o=window.localStorage.getItem("permutive-id");o&&(window.googletag.pubads().setTargeting("puid",o),window.googletag.pubads().setTargeting("ptime",Date.now().toString())),window.permutive.config.viewId&&window.googletag.pubads().setTargeting("prmtvvid",window.permutive.config.viewId),window.permutive.config.workspaceId&&window.googletag.pubads().setTargeting("prmtvwid",window.permutive.config.workspaceId)}});

                let data = document.cookie.replace(/(?:(?:^|.*;\s*)autorefresh\s*\=\s*([^;]*).*$)|^.*$/, "$1").split("|");
	            let _weizenbockUser = WAuth.getUserSync();
                let date = new Date();

                permutive.addon('web',{
                    "page":{
                        "tecnico": {
                            "cks": WeizenBockCompatible.getCookieConsent() == "1" ? true : false,
                            "autorefresh": data[1] != 'undefined' ? data[1] : "0", 
                            "cl_height": window.innerHeight, 
                            "cl_width": window.innerWidth
                        },
                        "anio_publicacion": date.getFullYear().toString(),
                        "mes_publicacion": (date.getMonth() + 1).toString(),
                        "dia_publicacion": date.getDate().toString(),
                        "section": "${sectionP}",
                        "tipoPagina": "${pageType != '-2' ? pageType : ''}",  
                        "notaTipo": "${newsType != '-2' ? newsType : ''}", 
                        "cl_bajadalist": [],
                        "cl_keywordslist": ${clKeywordslist},
                        "cl_tagslist": ${tagsArray},
                        "kw_suscriptor": _weizenbockUser?.subscriptionProducts[0] ? _weizenbockUser.subscriptionProducts[0] : "",
                        "kw_edad": _weizenbockUser?.fechaNacimiento != null && _weizenbockUser?.fechaNacimiento != "" ? WeizenBockCompatible.getAge(_weizenbockUser.fechaNacimiento) : 0 ,
                        "kw_sexo":_weizenbockUser?.genero != undefined ? _weizenbockUser.genero : "",
                        // "kw_adblock": isAdBlockActive ? true : false,
                        "kw_choque": WeizenBockCompatible.getPaywallShock() == "" ? false : true,
                        "kw_lector": WeizenBockCompatible.getTypeReader(_weizenbockUser)
                    }
                });
            `
        }} />
        <Script strategy="afterInteractive" dangerouslySetInnerHTML={{
            __html: `
                const sendEmail =  async (e) => {
                    const user = JSON.parse(localStorage.getItem('wads_userProfile'));
                    if (typeof user !== 'undefined' && user !== null) {
                        const text = user.email;
                        async function digestMessage(message) {
                            const msgUint8 = new TextEncoder().encode(message);
                            const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
                            const hashArray = Array.from(new Uint8Array(hashBuffer));
                            const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
                            return hashHex;
                        }
                        const digestHex = await digestMessage(text);			
                        permutive.identify([
                            {
                                "id": digestHex,
                                "tag": "email_sha256"
                            }
                        ])
                    }
                }
                sendEmail();
            `
        }} />
        <Script strategy="afterInteractive" async src={`https://07652d0f-3e99-4279-ab32-950ddf1523f0.edge.permutive.app/${workspaceId}-web.js`}/>
    </>);
};
export default Permutive;