import Script from "next/script";

const GoogleTagManager = ({
    publishedDate,
    nsSite,
    tags,
    name,
    blockPW,
    hardLW,
    hardPW,
    infobipId,
    defaultValue,
    notaId,
    multimedia,
    pageType,
    newsType,
    cdSections,
    authors,
    thumbnail
}) => {
    const googleTagManagerAccountId = "GTM-J5TM";
    const spanishDay = [
        "domingo",
        "lunes",
        "martes",
        "miércoles",
        "jueves",
        "viernes",
        "sábado",
    ];
    return (
        <>
            <Script
                suppressHydrationWarning
                strategy="beforeInteractive"
                async="true"
                dangerouslySetInnerHTML={{
                    __html: `
                window.refreshChecker = {
                    init : function () {
                        var data =  this.getValueFromCookie("autorefresh").split("|");
                        if ( data[0] == '' || data[0] != window.location.pathname) {
                            this.setValueToCookie("autorefresh",window.location.pathname + "|0");
                        } else if (data[0] == window.location.pathname  &&  data[1] == "0" ) {
                            this.setValueToCookie("autorefresh", window.location.pathname + "|1");
                        }
                    },
                    getValueFromCookie :function (cookieName) {
                        var reg = cookieName + '\\=([^\\;]+)';
                        var matches = document.cookie.match(new RegExp(reg));
                        return matches != null && matches.length > 1 ? matches[1] : '';
                    },
                    setValueToCookie: function (cookieName, value) {
                        document.cookie = cookieName + "=" + value + ";path=/;";
                    },
                    delete_cookie: function (name) {
                        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;';
                    },
                    isAutorefresh: function () {
                        var data = this.getValueFromCookie("autorefresh").split("|");
                        return typeof data[1] != 'undefined' ? data[1] : "0";
                    }
                };    
                refreshChecker.init();

                window.parse_query_string = (query) => {
                    var vars = query.split("&");
                    var query_string = {};
                    for (var i = 0; i < vars.length; i++) {
                        var pair = vars[i].split("=");
                        var key = decodeURIComponent(pair[0]);
                        key = key.toLowerCase();
                        var value = decodeURIComponent(pair[1]);
                        // If first entry with this name
                        if (typeof query_string[key] === "undefined") {
                            query_string[key] = decodeURIComponent(value);
                            // If second entry with this name
                        } else if (typeof query_string[key] === "string") {
                            var arr = [query_string[key], decodeURIComponent(value)];
                            query_string[key] = arr;
                            // If third or later entry with this name
                        } else {
                            query_string[key].push(decodeURIComponent(value));
                        }
                    }
                    return query_string;
                }

                var parameters = parse_query_string(location.search.slice(1));
                
                window.getCampaign = () => {
                    if (typeof parameters.kw_campaign != "undefined" ) {
                        return parameters.kw_campaign;
                    }
                    if (typeof parameters.utm_campaign != "undefined" ) {
                        return parameters.utm_campaign;
                    }
                    return "-2";
                }
            
                window.getReferrer = () => {
                    if (typeof parameters.fromref != "undefined" ) {
                        return parameters.fromref;
                    } else  if (document.referrer.match(/t\.co/)) {
                        return 'twitter';
                    } else if (document.referrer.match(/facebook/)) {
                        return 'facebook';
                    }
                    return "-2";
                }
                
                window.sendGAPageview = (customParams) => {
                    customParams = customParams || {};                
                    try{                        
                        window.dataLayer.push(
                         customParams
                        );                
                    }catch(e){
                        setTimeout(function(){
                            sendGAPageview(customParams);
                        }, 200);
                    }
                }


                window.isAppPage = () => {                                   
                    const queryString = window.location.search;
                    const urlParams = new URLSearchParams(queryString);
                    if (urlParams.get('app') === 'true') {                        
                        return 'app';
                    } else {
                        const value = "; " + document.cookie;
                        const parts = value.split("; app=");
                        if (parts.length === 2){ 
                            if (parts.pop().split(';').shift() == 'true'){                                                                            
                                return 'app';
                            }
                        }
                    } 
                    return 'web';
                }
                if (typeof window._weizenbockAuth0 === "undefined") {
                    window._weizenbockAuth0 = WAuth.getUserSync();
                }
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'sendPageview',
                    'comscoreParams': {c1: "2", c2: "6906409"}, 
                    'cd_paseId': _weizenbockAuth0 != null && typeof _weizenbockAuth0.id != "undefined" && _weizenbockAuth0.id != null && _weizenbockAuth0.id != "" ? _weizenbockAuth0.id : "${defaultValue}",
                    'cd_nsSite': "${nsSite}",
                    'cd_sections': "${cdSections}",
                    'cd_adblock': "no",
                    'cd_nsListaTags': "${tags.replace(/"/g, "'")}",
                    'cd_name': "${name}",
                    'cd_autorefresh': refreshChecker.isAutorefresh(),
                    'cd_anioPublicacion': "${publishedDate.getFullYear()}",
                    'cd_mesPublicacion': "${String(
                        publishedDate.getMonth() + 1
                    ).padStart(2, "0")}",
                    'cd_diaPublicacion': "${String(
                        publishedDate.getDate()
                    ).padStart(2, "0")}",
                    'cd_horaPublicacion': "${publishedDate.getHours() +
                        ":" +
                        (publishedDate.getMinutes() < 10 ? "0" : "") +
                        publishedDate.getMinutes()
                        }",
                    'cd_diaSemanaPublicacion': "${spanishDay[publishedDate.getDay()]
                        }",
                    'cd_tituloNota': document.title,
                    'cd_contadorGral': WeizenBockCompatible.getPaywallCount(),
                    'cd_blockLW': WeizenBockCompatible.getStatusSw(),
                    'cd_blockPW': "${blockPW}",
                    'cd_hardLW': "${hardLW}",
                    'cd_hardPW': "${hardPW}",
                    'cd_notaId': "${notaId}",
                    'cd_temperatura': "${defaultValue}",
                    'cd_pwAudiencia': "${defaultValue}",
                    'cd_sexo': typeof _weizenbockAuth0?.auth0["https://clarin.com/genero"] !== 'undefined' ? WeizenBockCompatible.getGen(_weizenbockAuth0.auth0["https://clarin.com/genero"]) : "${defaultValue}",
                    'cd_edad': typeof _weizenbockAuth0?.auth0["https://clarin.com/fechaNacimiento"] !== 'undefined' ? WeizenBockCompatible.getAge(_weizenbockAuth0.auth0["https://clarin.com/fechaNacimiento"]) : "${defaultValue}",
                    'cd_tipoPagina': "${pageType}",
                    /* 'cd_multimedia': "${multimedia}", */
                    'cd_tipoSuscriptor': "${defaultValue}",
                    'cd_tipoLector':  WeizenBockCompatible.getTypeReader(_weizenbockAuth0),
                    /* 'cd_concursos': "${defaultValue}", */
                    'cd_referrer': getReferrer(),
                    'cd_pwCounter': WeizenBockCompatible.getPaywallCount(),
                    'cd_choque': WeizenBockCompatible.getPaywallShock(),
                    'cd_paseFlujo': "${defaultValue}",
                    'cd_planSeleccionado': "${defaultValue}",
                    'cd_infobipId': "${infobipId}",
                    /* 'cd_urlMultimedia': document.URL, */
                    /* 'cd_durMultimedia': "${defaultValue}", */
                    'cd_userDataImportAudiencias': "${defaultValue}",
                    /* 'cd_errorCollect': "${defaultValue}", */
                    'cd_notaTipo': "${newsType}",
                    'cd_campania': getCampaign(),
                    /* 'cd_FP_user': typeof WeizenBockCompatible.getFingerprint == "function" ? WeizenBockCompatible.getFingerprint():"-2", */
                    /* 'cd_FP_hit': typeof WeizenBockCompatible.getFingerprint == "function" ? WeizenBockCompatible.getFingerprint():"-2", */
                    'cd_notaShared': "${defaultValue}",
                    'dataSource': window.isAppPage(),
                    'cd_autor' : "${authors || 'Redacción Clarín'}",
                    'cd_thumbnail': "${thumbnail || '-2'}"
                });
                try {
                    if(typeof(window.isPrivateMode) == "undefined") {
                        throw 'waiting bprivate';
                    }
                    window.dataLayer.push({ 
                        'cd_bprivate': window.isPrivateMode,
                    });
                } catch(e){
                    setTimeout(function(){
                        window.dataLayer.push({ 
                            'cd_bprivate': window.isPrivateMode,                            
                        });
                    }, 200);
                }
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${googleTagManagerAccountId}');           
            `,
                }}
            />
        </>
    );
};
export default GoogleTagManager;
