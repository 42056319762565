import { parseData, parseKeyword, parseSummary, cdSections } from "helpers";
import GoogleTagManager from "./GoogleTagManager";
import Permutive from "./Permutive";
import Weizenbock from "./Weizenbock";
import { useContext } from "react";
import TemplateContext from "@/context/Template/TemplateContext";

const Analitics = (props) => {

  const dataContext = useContext(TemplateContext);
  const { content: formatContent, ...rest } = dataContext || {};
  // si no tengo props, busco contexto
  if(Object.keys(props).length === 0 && !!formatContent) {
        props = { 
            ...props,
            ...formatContent?.dataAnalitics,
        };
  } 

  const {
    sections,
    pageType = "-2",
    newsType = "-2",
    tags,
    summary,
    nsSite = "",
    name = "",
    notaId = "-2",
    blockPW = "no",
    hardLW = "no",
    hardPW = "no",
    infobipId = "-2",
    multimedia = "-2",
    defaultValue = "-2",
    asPath,
    publishedDate,
    section = "",
    sectionNames,
    authors,
    thumbnail
  } = props;

  const data = {
    publishedDate: new Date(publishedDate),
    sections: parseData(sections, "nsSite", true).replace(/^\[('|")|('|")\]$/g, ''),
    clKeywordslist: parseData(sections, "nsSite", true),
    pageType,
    newsType,
    tags: parseData(tags, "name"),
    tagsArray: parseData(tags, "slug", true),
    summary: parseSummary(summary?.replace(/'/g, '')),
    nsSite,
    name,
    notaId,
    blockPW,
    hardLW,
    hardPW,
    infobipId,
    multimedia,
    defaultValue,
    keyword: parseKeyword(asPath),
    cdSections: cdSections(sectionNames),
    section: section,
    authors,
    thumbnail
  };
  return (
    <>
      <Weizenbock />
      <GoogleTagManager {...data} />
      <Permutive {...data}/>
    </>
  );
};

export default Analitics;
